import React from 'react';
import { useForm } from 'react-hook-form';
import InputGroup from '../../components/InputGroup';
import { EMAIL_REGEX, NAME_REGEX } from '../../lib/regex';
import Button from '../Button';
import {
  StorefrontPatientSignUpError,
  useStorefrontPatientSignUpMutation,
} from '../../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import { usePatientAuth } from '../../../contexts/PatientAuthContext';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';
import {
  DEFAULT_CTA_BUTTON_TEXT,
  DEFAULT_CTA_FORM_LABEL,
} from '../../pages/MyWebpage';

interface StorefrontSignUpFormData {
  fullName: string;
  email: string;
}

const StorefrontSignUp: React.FC<{
  providerName: string;
  ctaFormLabel?: string;
  ctaButtonText?: string;
  enabled?: boolean;
  providerStorefrontSlug?: string;
  includeLoginLink?: boolean;
  inEditMode?: boolean;
  editModeCTAFormLabelChildren?: React.ReactNode;
  editModeCTAButtonTextChildren?: React.ReactNode;
}> = ({
  providerName,
  ctaFormLabel,
  ctaButtonText,
  enabled = true,
  providerStorefrontSlug,
  includeLoginLink = false,
  inEditMode = false,
  editModeCTAFormLabelChildren,
  editModeCTAButtonTextChildren,
}) => {
  const { updateTokenPayload } = usePatientAuth();
  const navigate = useNavigate();
  const [storefrontSignUp] = useStorefrontPatientSignUpMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors: formErrors, isSubmitting: isFormSubmitting },
  } = useForm<StorefrontSignUpFormData>();

  const onSubmit = async (formData: StorefrontSignUpFormData) => {
    if (!providerStorefrontSlug || !enabled) {
      return;
    }

    const { email, fullName } = formData;

    try {
      const { data } = await storefrontSignUp({
        variables: {
          input: {
            email,
            name: fullName,
            storefrontSlug: providerStorefrontSlug,
          },
        },
      });

      const storefrontPatientSignUpData = data?.storefrontPatientSignUp;

      if (storefrontPatientSignUpData?.error) {
        const storefrontError = storefrontPatientSignUpData.error;
        switch (storefrontError) {
          case StorefrontPatientSignUpError.InvalidStorefrontSlug:
            setError('root.serverError', {
              message: `We couldn't find the provider you're trying to connect with. Please try again later.`,
            });
            break;
          case StorefrontPatientSignUpError.EmailAlreadyExistsOtherProvider:
            setError('root.serverError', {
              message: `It looks like you're already working with another provider on Homecoming. Please provide another email to work with ${providerName}`,
            });
            break;
          case StorefrontPatientSignUpError.EmailAlreadyExistsThisProvider:
            setError('root.serverError', {
              message: `It looks like you're already working with ${providerName}. We've sent you an email with a link to your client portal.`,
            });
            break;
          default:
            break;
        }
        return;
      }

      if (storefrontPatientSignUpData?.authToken) {
        updateTokenPayload({
          authSessionToken: storefrontPatientSignUpData.authToken,
        });
        navigate('/client/intake', { state: { fullName } });
      }
    } catch (error) {
      setError('root.serverError', {
        message:
          "Something went wrong. Please contact support@homecoming.health and we'll help you get connected.",
      });
    }
  };

  const isMobileDevice = useIsMobileDevice();

  return (
    <div className="flex w-full flex-col items-center">
      {!inEditMode ? (
        <div className="mb-4 text-body font-medium text-neutral-150">
          {ctaFormLabel || DEFAULT_CTA_FORM_LABEL}
        </div>
      ) : (
        editModeCTAFormLabelChildren
      )}
      <form className="w-full">
        <InputGroup
          placeholder="Full name"
          autoComplete="name"
          type="text"
          inputSize="small"
          useNaturalLettering={true}
          containerClassName="mb-2"
          className="rounded-full !border border-neutral-75 !placeholder-neutral-100"
          backgroundHidden
          required
          disabled={!enabled}
          errorMessage={formErrors.fullName?.message}
          {...register('fullName', {
            required: 'Name is required',
            pattern: {
              value: NAME_REGEX,
              message: 'Please enter your first and last name',
            },
          })}
        />
        <InputGroup
          placeholder="Email"
          autoComplete="email"
          type="email"
          inputSize="small"
          useNaturalLettering={true}
          className="rounded-full !border border-neutral-75 !placeholder-neutral-100"
          backgroundHidden
          required
          disabled={!enabled}
          errorMessage={formErrors.email?.message}
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Please enter a valid email',
            },
          })}
        />
        {!inEditMode ? (
          <Button
            title={ctaButtonText || DEFAULT_CTA_BUTTON_TEXT}
            className="mx-auto my-6 w-full disabled:cursor-default md:mt-8"
            type="submit"
            disabled={!enabled || isFormSubmitting}
            onClick={handleSubmit(onSubmit)}
          />
        ) : (
          editModeCTAButtonTextChildren
        )}
        <ErrorMessage className="mx-auto mb-4 text-center">
          {formErrors.root?.serverError?.message}
        </ErrorMessage>
      </form>
      {includeLoginLink && (
        <div className="flex flex-col items-center text-small-caption text-neutral-125">
          <span className="font-medium">Already a client?</span>
          <span className="ml-2">
            <span
              onClick={() => navigate('/login/client')}
              className="cursor-pointer font-medium text-green-100 hover:underline"
            >
              {isMobileDevice ? 'Tap' : 'Click'} here to log in
            </span>{' '}
            to your client portal
          </span>
        </div>
      )}
    </div>
  );
};

export default StorefrontSignUp;
