import React from 'react';
import Button from '../../components/Button';
import { EyeIcon, ShareIcon } from '@heroicons/react/outline';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { StorefrontFormData } from '.';
import ToastAlert from '../../components/ToastAlert';
import toast from 'react-hot-toast';
import IllustrationWeb from '../../svgs/IllustrationWeb';
import {
  AnalyticsPage,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import { storefrontUrlFromSlug } from '../../../lib/storefront';

const TopBanner: React.FC<{
  storefrontSlug: string;
  updateStorefrontEnabled: (isEnabled: boolean) => Promise<void>;
}> = ({ storefrontSlug, updateStorefrontEnabled }) => {
  const storefrontUrl = storefrontUrlFromSlug(storefrontSlug);

  const { watch } = useFormContext<StorefrontFormData>();

  const isStorefrontEnabled = watch('isEnabled');

  const handleSeeMyWebpage = () => {
    window.open(storefrontUrl, '_blank');
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: 'See my webpage',
      originPage: AnalyticsPage.MyWebpage,
    });
  };

  const handleCopyMyWebpage = () => {
    navigator.clipboard.writeText(storefrontUrl);
    toast.custom(({ visible }) => (
      <ToastAlert
        isVisible={visible}
        level="success"
        message="Webpage link copied to clipboard!"
      />
    ));
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: 'Share my webpage',
      originPage: AnalyticsPage.MyWebpage,
    });
  };

  return (
    <div className="relative flex flex-col overflow-hidden rounded-lg bg-neutral-50 py-8 px-10">
      <IllustrationWeb
        className={classNames(
          'absolute top-[-1rem] right-[-1.5rem] h-[11.875rem] w-[11.75rem] ',
          isStorefrontEnabled ? 'text-green-50' : 'text-neutral-100',
        )}
      />
      <div className="z-10 mb-2 text-subtitle text-neutral-150">
        Launch your webpage in a flash
      </div>
      <div className="z-10 mb-11 w-1/2 text-caption text-neutral-125">
        Effortlessly create your online space, streamline client
        acquisition&mdash;from discovery calls to intake forms&mdash;and enable
        easy session bookings. Simplify your setup, focus on your practice.
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-grow flex-col items-start gap-y-4 lg:flex-row lg:items-center lg:gap-x-4">
          <Button
            title="See my webpage"
            subLabel={storefrontUrl}
            IconComponent={EyeIcon}
            iconPosition="left"
            theme="secondary-white"
            disabled={!isStorefrontEnabled}
            onClick={handleSeeMyWebpage}
            circular
          />
          <Button
            title="Share my webpage"
            subLabel={storefrontUrl}
            IconComponent={ShareIcon}
            iconPosition="left"
            theme="secondary-white"
            disabled={!isStorefrontEnabled}
            onClick={handleCopyMyWebpage}
            circular
          />
        </div>
        {/* Null check is needed so Switch doesn't animate on load */}
        {isStorefrontEnabled != null && (
          <div className="ml-4 flex flex-row items-center space-x-4">
            <div className="text-caption font-bold text-green-150">
              My webpage is active
            </div>
            <Switch
              checked={isStorefrontEnabled}
              onChange={async () => {
                await updateStorefrontEnabled(!isStorefrontEnabled);
              }}
              className={classNames(
                !isStorefrontEnabled ? 'bg-neutral-100' : 'bg-green-125',
                'relative inline-flex h-[31px] w-[58px] flex-shrink-0 cursor-pointer rounded-full',
                'transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-125 focus:ring-offset-2',
              )}
            >
              <div
                className={classNames(
                  !isStorefrontEnabled ? 'translate-x-0' : 'translate-x-full',
                  'pointer-events-none absolute top-[2px] left-0.5 inline-block h-[27px] w-[27px]',
                  'transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              ></div>
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBanner;
