import React, { useEffect, useRef, useState } from 'react';
import { PlusSmIcon, SparklesIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { StorefrontFormData } from '..';
import Button from '../../../components/Button';
import AddResourceModal, {
  AddResourceModalRef,
} from '../../../components/Modals/AddResourceModal';
import { ContentType } from '../../../lib/followUp';
import {
  ActivityDataFragment,
  ActivityType,
  useActivityLazyQuery,
} from '../../../../generated/graphql';
import { ProviderActivityType } from '../../../types/activity';
import ContentPreview from '../../../components/ContentPreview';
import ProviderActivitySlideover from '../../../components/Library/ProviderActivitySlideover';
import { useNavigate } from 'react-router-dom';
import ArrowRightLong from '../../../svgs/ArrowRightLong';

const IntakeFormModule: React.FC<{
  addIntakeForm?: (formAssessmentId: string) => Promise<void>;
  removeIntakeForm?: () => Promise<void>;
  className?: string;
}> = ({ addIntakeForm, removeIntakeForm, className }) => {
  const navigate = useNavigate();

  const addResourceModalRef = useRef<AddResourceModalRef | null>(null);
  const [isAddResourceModalOpen, setIsAddResourceModalOpen] = useState(false);
  const [isProviderActivitySlideoverOpen, setIsProviderActivitySlideoverOpen] =
    useState(false);

  const { watch, setValue } = useFormContext<StorefrontFormData>();

  const watchIntakeFormAssessment = watch('intakeFormAssessment');

  const formName = watchIntakeFormAssessment?.name;

  // Fetches the Activity for the currently selected intake form, which we only have the assessment ID for
  // in order to hydrate the ProviderActivitySlideover with the correct generalized Activity shape.
  const [getActivityQuery, { data: activityData }] = useActivityLazyQuery();

  const selectedProviderActivity = activityData?.activity;

  useEffect(() => {
    if (watchIntakeFormAssessment) {
      getActivityQuery({
        variables: {
          activityId: watchIntakeFormAssessment.id,
          activityType: ActivityType.Assessment,
        },
      });
    }
  }, [watchIntakeFormAssessment]);

  const handleProviderActivitiesSelected = async (
    providerActivities: ActivityDataFragment[],
  ) => {
    // We're expecting here that the AddResourceModal can only return one activity and that it will
    // be a Form based on its restrictions set in this component in its props.
    const providerActivity = providerActivities?.[0];
    if (
      !providerActivity ||
      providerActivity.__typename !== ProviderActivityType.Assessment
    ) {
      return;
    }

    // Optimistically update the form data
    setValue('intakeFormAssessment', providerActivity.assessment);

    await addIntakeForm?.(providerActivity.id);
  };

  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-hidden px-6 py-10 md:items-center',
        className,
      )}
    >
      <div className="flex flex-col items-center md:w-2/3">
        <div className="mb-12">
          {!watchIntakeFormAssessment ? (
            <div className="flex flex-row items-center space-x-3 md:space-x-4">
              <Button
                title="Use an existing intake form"
                theme="primary"
                iconPosition="left"
                size="medium"
                IconComponent={PlusSmIcon}
                onClick={() => {
                  setIsAddResourceModalOpen(true);
                }}
              />
              <Button
                title="Create a new intake form"
                theme="alt"
                className="h-12 hover:text-green-100 focus:outline-none focus:ring-0"
                iconClassName="text-green-125 group-hover:text-green-100"
                size="medium"
                IconComponent={ArrowRightLong}
                iconPosition="right"
                onClick={() => {
                  navigate('/library/forms/new', {
                    state: {
                      fromPage: 'my-webpage',
                    },
                  });
                }}
              />
            </div>
          ) : (
            <div className="flex flex-row items-center space-x-10">
              <ContentPreview
                className="max-w-[500px] border border-neutral-75 !p-4 shadow-none"
                contentType={ContentType.Form}
                title={formName}
                onClick={() => setIsProviderActivitySlideoverOpen(true)}
                onClickCloseX={removeIntakeForm}
              />
              <Button
                title="Preview"
                theme="secondary-white"
                noBackground
                noOutline
                onClick={() => {
                  setIsProviderActivitySlideoverOpen(true);
                }}
              />
            </div>
          )}
        </div>
        <div className="flex items-start text-neutral-125 md:w-full md:max-w-[500px]">
          <SparklesIcon className="mr-2 mt-1 h-5 w-5 flex-shrink-0" />
          <div className="text-caption">
            Homecoming collects{' '}
            <span className="font-medium">full names and emails</span> directly
            from your prospective clients, so you can skip these details on your
            Intake Form.
          </div>
        </div>
      </div>
      <AddResourceModal
        isModalOpen={isAddResourceModalOpen}
        setClosed={() => setIsAddResourceModalOpen(false)}
        contentTypeFilterLock={ContentType.Form}
        restrictSingleSelection
        onAddProviderActivities={handleProviderActivitiesSelected}
        ref={addResourceModalRef}
        ignoreMultiSignatureForms
      />
      <ProviderActivitySlideover
        isOpen={isProviderActivitySlideoverOpen}
        onClose={() => setIsProviderActivitySlideoverOpen(false)}
        selectedProviderActivity={selectedProviderActivity}
        followUpMode
      />
    </div>
  );
};

export default IntakeFormModule;
