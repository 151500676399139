import * as Sentry from '@sentry/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthedContainerV1 from './route-containers/AuthedContainerV1';
import AuthedContainerV2 from './route-containers/AuthedContainerV2';
import CatchAll from './route-containers/CatchAll';
import UnauthedContainer from './route-containers/UnauthedContainer';

import JoinSignup from './v2/pages/JoinSignup';
import Logout from './v2/pages/Logout';
import ResetPassword from './v2/pages/ResetPassword';

/* v1 Pages + Components */
import PatientManagement from './v1/pages/PatientManagement';
import PatientsTable from './v1/pages/PatientManagement/Table';
import PatientProfile from './v1/pages/PatientProfile';
import ProgramManagement from './v1/pages/ProgramManagement';
import ProgramOverview from './v1/pages/ProgramOverview';
import ProgramAssessmentsTab from './v1/pages/ProgramOverview/AssessmentTab';
import ProgramContentTab from './v1/pages/ProgramOverview/ContentTab';
import ProgramTask from './v1/pages/ProgramTask';
import ScheduleAssessment from './v1/pages/ScheduleAssessment';
import Settings from './v1/pages/Settings';
import MembersSection from './v1/pages/Settings/MembersSection';
import SettingsSection from './v1/pages/Settings/SettingsSection';

import BillingSection from './v1/components/Billing/BillingSection';
import BillingSuccess from './v1/components/Billing/BillingSuccess';
import OnboardingRoutes from './v1/components/OnboardingRoutes';

/* v2 Pages + Components */
import FollowUpBuilder from './v2/pages/FollowUpBuilder';
import FormBuilder from './v2/pages/FormBuilder';
import Home from './v2/pages/Home';
import Library from './v2/pages/Library';
import LogoutV2 from './v2/pages/Logout';
import PatientManagementV2 from './v2/pages/PatientManagement';
import PatientsTableV2 from './v2/pages/PatientManagement/Table';
import PatientProfileV2 from './v2/pages/PatientProfile';
import PatientProfileAccountTab from './v2/pages/PatientProfile/Account';
import PatientProfileActivitiesTab from './v2/pages/PatientProfile/Activities';
import PatientProfileDocumentsTab from './v2/pages/PatientProfile/Documents';
import PatientProfileFollowUpsTab from './v2/pages/PatientProfile/FollowUps';
import PatientProfileMessagesTab from './v2/pages/PatientProfile/Messages';
import PatientProfileNotesTab from './v2/pages/PatientProfile/Notes';
import PatientProfileInvoicesTab from './v2/pages/PatientProfile/Invoices';
import Schedule from './v2/pages/Schedule';
import Payments from './v2/pages/Payments';

import BillingSectionV2 from './v2/components/Billing/BillingSection';
import SettingsV2 from './v2/pages/Settings';
import IntegrationsSection from './v2/pages/Settings/IntegrationsSection';
import AccountSection from './v2/pages/Settings/AccountSection';

import BillingSuccessV2 from './v2/components/Billing/BillingSuccess';
import SubscriptionUpdated from './v2/components/Billing/SubscriptionUpdated';
import usePageViewTracking from './v2/hooks/usePageViewTracking';
import ClientPdfSubmission from './v2/pages/ClientPdfSubmission';
import CalendlyIntegration from './v2/pages/Integrations/Calendly';
import CalendlyIntegrationCallback from './v2/pages/Integrations/Calendly/Callback';
import StripeIntegration from './v2/pages/Integrations/Stripe';
import StripeIntegrationCallback from './v2/pages/Integrations/Stripe/Callback';
import Intro from './v2/pages/Intro';
import FollowUpDetails from './v2/pages/PatientProfile/FollowUps/FollowUpDetails';
import NoteDetails from './v2/pages/PatientProfile/Notes/NotesDetails';
import NotificationsSection from './v2/pages/Settings/NotificationsSection';
import SignUp from './v2/pages/SignUp';
import StripeCheckoutRedirect from './v2/pages/StripeCheckoutRedirect';
import Subscribe from './v2/pages/Subscribe';
import InvoiceBuilder from './v2/pages/InvoiceBuilder';
import TagsSection from './v2/pages/Settings/TagsSection';
import TeamSection from './v2/pages/Settings/TeamSection';
import MyWebpage from './v2/pages/MyWebpage';
import PublicStorefront from './v2/pages/PublicStorefront';
import AuthedPatientContainer from './route-containers/AuthedPatientContainer';
import PatientIntake from './v2/pages/ClientPortal/PatientIntake';
import PatientCatchAll from './route-containers/PatientCatchAll';
import AuthCallback from './v2/pages/AuthCallback';
import Community from './v2/pages/Community';
import CommunityLanding from './v2/pages/CommunityLanding';
import LoginRedirect from './v2/pages/LoginRedirect';
import SubscribeEmbed from './v2/pages/SubscribeEmbed';
import PatientLogin from './v2/pages/ClientPortal/PatientLogin';
import PatientHome from './v2/pages/ClientPortal/PatientHome';
import PatientLoginToken from './v2/pages/ClientPortal/PatientLoginToken';
import PatientFollowUpDetails from './v2/pages/ClientPortal/PatientFollowUpDetails';
import PatientMessages from './v2/pages/ClientPortal/PatientMessages';
import PatientLibrary from './v2/pages/ClientPortal/PatientLibrary';
import PatientSessions from './v2/pages/ClientPortal/PatientSessions';
import CompletePatientForm from './v2/pages/ClientPortal/CompletePatientForm';
import CompleteProviderForm from './v2/pages/CompleteProviderForm';
import NylasIntegrationCallback from './v2/pages/Integrations/Nylas/Callback';
import NylasIntegration from './v2/pages/Integrations/Nylas';

export enum HomecomingVersion {
  V1 = 'V1',
  V2 = 'V2',
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// Named to avoid collisions with React Router components
const AppRoutes: React.FC<{
  homecomingVersion: HomecomingVersion;
}> = ({ homecomingVersion }) => {
  // Must be used within the Router context
  usePageViewTracking();

  // Allows Commandbar to use React Router to navigate when it links to parts of the app
  // instead of hard refreshing. I think this has to be done here in a useEffect so that
  // we have access to the navigate function and can be sure its initialized.
  // Saving this commented out b/c we're going to need to do something similar for Intercom
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (navigate) {
  //     const routerFunc = (url: string) => navigate(url);
  //     CommandbarHelpers.addCommandbarRouter(routerFunc);
  //   }
  // }, [navigate]);

  return (
    <SentryRoutes>
      {/* Public routes */}
      <Route path="/pricing-embed" element={<SubscribeEmbed />} />

      {/* Important for AuthCallback to be outside of UnauthedContainer because it changes auth state */}
      <Route path="/auth-callback" element={<AuthCallback />} />

      {/* Unauthenticated routes */}
      <Route element={<UnauthedContainer />}>
        <Route path="/login" element={<LoginRedirect />} />

        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/join">
          <Route index element={<JoinSignup />} />
        </Route>
        <Route path="/sign-up" element={<SignUp />}>
          <Route path=":slug" element={<SignUp />} />
        </Route>

        {/* Client app */}
        <Route path="/login/client" element={<PatientLogin />} />
        <Route path="/client" element={<AuthedPatientContainer />}>
          <Route index element={<Navigate to="home" replace />} />
          <Route path="home" element={<PatientHome />} />
          <Route path="login-token/:tokenId" element={<PatientLoginToken />} />
          <Route path="intake" element={<PatientIntake />} />

          <Route path="follow-ups" element={<PatientFollowUpDetails />} />
          <Route
            path="follow-ups/:programFollowUpId"
            element={<PatientFollowUpDetails />}
          />
          <Route path="messages" element={<PatientMessages />} />
          <Route path="library" element={<PatientLibrary />} />
          <Route path="sessions" element={<PatientSessions />} />
          <Route
            path="client-form/:programActivityId"
            element={<CompletePatientForm />}
          />
          <Route path="*" element={<PatientCatchAll />} />
        </Route>
      </Route>

      {/* Storefront */}
      <Route path="/p/:slug" element={<PublicStorefront />} />

      {/* Client query-token-authenticated routes */}
      <Route path="/file-submission" element={<ClientPdfSubmission />} />

      {/* authed v1 routes */}
      {homecomingVersion === HomecomingVersion.V1 && (
        <Route path="/" element={<AuthedContainerV1 />}>
          <Route index element={<Navigate to="clients/active" replace />} />
          <Route path="clients" element={<PatientManagement />}>
            <Route index element={<Navigate to="active" replace />} />
            <Route path="onboarding" element={<PatientsTable />} />
            <Route path="invited" element={<PatientsTable />} />
            <Route path="active" element={<PatientsTable />} />
            <Route path="archived" element={<PatientsTable />} />
          </Route>

          <Route path="clients/:programId" element={<PatientProfile />} />
          <Route path="programs" element={<ProgramManagement />} />
          <Route
            path="programs/:programTemplateId"
            element={<ProgramOverview />}
          >
            <Route index element={<Navigate to="content" replace />} />
            <Route path="content" element={<ProgramContentTab />} />
            <Route path="assessments" element={<ProgramAssessmentsTab />} />
          </Route>
          <Route
            path="programs/:programTemplateId/assessments/:assessmentId/schedule"
            element={<ScheduleAssessment />}
          />
          <Route
            path="programs/:programTemplateId/task/:taskId"
            element={<ProgramTask />}
          />
          <Route path="onboarding/*" element={<OnboardingRoutes />} />
          <Route path="settings" element={<Settings />}>
            <Route index element={<SettingsSection />} />
            <Route path="team" element={<MembersSection />} />
            <Route path="billing" element={<BillingSection />} />
          </Route>
          <Route path="billing">
            <Route path="success" element={<BillingSuccess />} />
          </Route>
          <Route path="logout" element={<Logout />} />
        </Route>
      )}

      {/* authed v2 routes */}
      {homecomingVersion === HomecomingVersion.V2 && (
        <Route path="/" element={<AuthedContainerV2 />}>
          <Route path="intro" element={<Intro />} />
          <Route path="community-landing" element={<CommunityLanding />} />
          <Route path="subscribe" element={<Subscribe />} />
          <Route path="stripe-checkout" element={<StripeCheckoutRedirect />} />

          <Route index element={<Navigate to="home" replace />} />
          <Route path="home" element={<Home />} />

          <Route path="follow-ups/new" element={<FollowUpBuilder />} />
          <Route
            path="/:programId/follow-ups/new/"
            element={<FollowUpBuilder />}
          />
          <Route
            path="/:programId/follow-ups/:programFollowUpDraftId"
            element={<FollowUpBuilder />}
          />

          <Route path="intake/new" element={<FollowUpBuilder />} />
          <Route path="/:programId/intake/new/" element={<FollowUpBuilder />} />

          <Route path="clients" element={<PatientManagementV2 />}>
            <Route index element={<Navigate to="active" replace />} />
            <Route path="active" element={<PatientsTableV2 />} />
            <Route path="archived" element={<PatientsTableV2 />} />
          </Route>

          <Route path="clients/:programId" element={<PatientProfileV2 />}>
            <Route index element={<Navigate to="follow-ups" replace />} />
            <Route path="messages" element={<PatientProfileMessagesTab />} />
            <Route path="follow-ups" element={<PatientProfileFollowUpsTab />}>
              <Route path=":programFollowUpId" element={<FollowUpDetails />} />
            </Route>
            <Route path="notes" element={<PatientProfileNotesTab />}>
              <Route path=":programNoteId" element={<NoteDetails />} />
            </Route>
            <Route
              path="activities"
              element={<PatientProfileActivitiesTab />}
            />
            {/* TODO: Renamed to documents, this is just a redirect for backwards-compatibility */}
            <Route
              path="forms-and-assessments"
              element={<Navigate to="../documents" replace />}
            />
            <Route
              path="documents-and-files"
              element={<PatientProfileDocumentsTab />}
            />
            <Route path="account" element={<PatientProfileAccountTab />} />
            <Route path="invoices" element={<PatientProfileInvoicesTab />} />
          </Route>

          <Route path="library" element={<Library />} />
          <Route path="community" element={<Community />} />

          <Route path="library/forms/new" element={<FormBuilder />} />
          <Route path="library/forms/:assessmentId" element={<FormBuilder />} />
          <Route
            path="provider-form/:programAssessmentId"
            element={<CompleteProviderForm />}
          />

          {/* Redirect for backwards-compatibility */}
          <Route
            path="schedule"
            element={<Navigate to="/calendar" replace />}
          />
          <Route path="calendar" element={<Schedule />} />

          <Route path="payments">
            <Route index element={<Payments />} />
            <Route path="new" element={<InvoiceBuilder />} />
          </Route>

          <Route path="settings" element={<SettingsV2 />}>
            <Route index element={<AccountSection />} />
            <Route path="team" element={<TeamSection />} />
            <Route path="notifications" element={<NotificationsSection />} />
            <Route path="billing" element={<BillingSectionV2 />} />
            <Route path="integrations" element={<IntegrationsSection />} />
            <Route path="tags" element={<TagsSection />} />
          </Route>

          <Route path="my-webpage" element={<MyWebpage />} />

          <Route path="integrations">
            <Route path="calendly">
              <Route index element={<CalendlyIntegration />} />
              <Route
                path="callback"
                element={<CalendlyIntegrationCallback />}
              />
            </Route>
            <Route path="stripe">
              <Route index element={<StripeIntegration />} />
              <Route path="callback" element={<StripeIntegrationCallback />} />
            </Route>
            <Route path="calendar">
              <Route index element={<NylasIntegration />} />
              <Route path="callback" element={<NylasIntegrationCallback />} />
            </Route>
          </Route>

          <Route path="billing">
            <Route path="success" element={<BillingSuccessV2 />} />
            <Route path="updated" element={<SubscriptionUpdated />} />
          </Route>

          <Route path="logout" element={<LogoutV2 />} />
        </Route>
      )}

      <Route path="*" element={<CatchAll />} />
    </SentryRoutes>
  );
};

export default AppRoutes;
