import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import {
  ArrowNarrowLeftIcon,
  CalendarIcon,
  ChatIcon,
} from '@heroicons/react/outline';

import { usePatientAuth } from '../../../../contexts/PatientAuthContext';

import {
  UserType,
  ActivityType,
  PatientScheduledEventFragment,
  ProgramActivityDataFragment,
  useCalendlyEventTypesQuery,
  useCompleteProgramActivityMutation,
  usePatientProgramFollowUpsQuery,
  usePatientScheduledEventsQuery,
  useTodoProgramActivitiesQuery,
} from '../../../../generated/graphql';

import { getFirstName } from '../../../lib/copy';
import { defaultTransitionProps } from '../../../lib/animation';

import Esign from '../../../components/Esign';
import Button from '../../../components/Button';
import {
  getProgramActivityStatus,
  ProgramActivityStatusOption,
} from '../../../components/ProgramActivityStatus';
import ToastAlert from '../../../components/ToastAlert';
import IconButton from '../../../components/IconButton';
import PatientMailCard from '../../../components/PatientMailCard';
import useIncrementNowDate from '../../../hooks/useIncrementNowDate';
import PageContainer from '../../../components/Containers/PageContainer';
import ProgramActivityCard from '../../../components/ProgramActivityCard';
import PatientScheduledEvent from '../../../components/PatientScheduledEvent';
import ConfirmCancelModal from '../../../components/Modals/ConfirmCancelModal';
import DownloadAppBanner from '../../../components/PatientApp/DownloadAppBanner';
import ProgramActivitySlideover from '../../../components/ProgramActivitySlideover';
import PatientCalendlyBookingModal from '../../../components/Modals/PatientCalendlyBookingModal';

import useIsMobileDevice from '../../../hooks/useIsMobileDevice';

import Spinner from '../../../svgs/Spinner';
import IllustrationMail from '../../../svgs/IllustrationMail';
import IllustrationCalendar from '../../../svgs/IllustrationCalendar';
import IllustrationClipboard from '../../../svgs/IllustrationClipboard';
import PatientHomeIllustration from '../../../svgs/PatientHomeIllustration';

import CompleteProgramAssessment from '../CompleteProgramAssessment';

import EmptySection from './EmptySection';
import SeeAllButton from './SeeAllButton';
import WelcomeText from './WelcomeText';
import useDisableOverscroll from '../../../hooks/useDisableOverscroll';
import { getPatientNameAndInitials } from '../CompleteProgramAssessment/ProgramAssessmentForm/helpers';

const CARD_SHORT_LIST_LENGTH = 2;

const PatientHome = () => {
  const navigate = useNavigate();
  const now = useIncrementNowDate();
  useDisableOverscroll();

  const isMobileDevice = useIsMobileDevice();

  const { authedPatient } = usePatientAuth();
  const { fullName: patientName } = getPatientNameAndInitials(authedPatient);
  const providerFirstName = getFirstName(authedPatient?.providerName);

  const [selectedScheduledEvent, setSelectedScheduledEvent] =
    useState<PatientScheduledEventFragment>();

  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);

  const [selectedProgramActivity, setSelectedProgramActivity] =
    useState<ProgramActivityDataFragment>();
  const [isProgramActivitySlideoverOpen, setIsProgramActivitySlideoverOpen] =
    useState<boolean>(false);

  const [assessmentProgramActivity, setAssessmentProgramActivity] =
    useState<ProgramActivityDataFragment | null>(null);
  const [esignProgramActivity, setEsignProgramActivity] =
    useState<ProgramActivityDataFragment | null>(null);

  const {
    data: todoProgramActivitiesQueryData,
    error: todoProgramActivitiesError,
    refetch: refetchTodoProgramActivities,
  } = useTodoProgramActivitiesQuery({
    variables: {
      programId: authedPatient?.programInstanceId,
    },
    skip: !authedPatient?.programInstanceId,
  });

  const todoProgramActivities =
    todoProgramActivitiesQueryData?.todoProgramActivities;

  const [completeProgramActivity] = useCompleteProgramActivityMutation();

  const hasCalendlyIntegration = authedPatient?.hasCalendlyIntegration;

  const {
    data: scheduledEventsData,
    error: scheduledEventsDataError,
    loading: scheduledEventsDataLoading,
    refetch: refetchPatientScheduledEvents,
  } = usePatientScheduledEventsQuery({
    variables: {
      input: {
        programId: authedPatient.programInstanceId,
        minStartTime: now,
        isCanceled: false,
      },
    },
    skip: !hasCalendlyIntegration,
  });

  const scheduledEvents = scheduledEventsData?.scheduledEvents.slice(
    0,
    CARD_SHORT_LIST_LENGTH,
  );

  const hasScheduledEvents = Boolean(scheduledEvents?.length);

  const { data: calendlyEventTypesData, error: calendlyEventTypesDataError } =
    useCalendlyEventTypesQuery({
      skip: !hasCalendlyIntegration,
      onError: (error) => {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Failed to get Calendly event types."
            level="error"
          />
        ));
      },
    });

  const calendlyEventTypes = calendlyEventTypesData?.calendlyEventTypes ?? [];

  const { data: programFollowUpsData, error: programFollowUpsError } =
    usePatientProgramFollowUpsQuery({
      variables: {
        programId: authedPatient?.programInstanceId,
      },
      skip: !authedPatient?.programInstanceId,
    });

  const programFollowUps = programFollowUpsData?.programFollowUps.slice(
    0,
    CARD_SHORT_LIST_LENGTH,
  );

  const [patientCalendlyBookingModalOpen, setPatientCalendlyBookingModalOpen] =
    useState(false);

  const handleOnClickProgramActivity = (
    programActivity: ProgramActivityDataFragment,
  ) => {
    if (
      programActivity.activityType === ActivityType.Assessment &&
      !programActivity.completedAt &&
      getProgramActivityStatus(programActivity, UserType.Patient) !==
        ProgramActivityStatusOption.PendingSignature
    ) {
      setAssessmentProgramActivity(programActivity);
    } else if (
      programActivity.activityType === ActivityType.Esign &&
      !programActivity.completedAt
    ) {
      setEsignProgramActivity(programActivity);
    } else {
      setSelectedProgramActivity(programActivity);
      setIsProgramActivitySlideoverOpen(true);
    }
  };

  if (assessmentProgramActivity) {
    return (
      <CompleteProgramAssessment
        authedPatient={authedPatient}
        assessmentProgramActivity={assessmentProgramActivity}
        onBack={() => setAssessmentProgramActivity(null)}
        onComplete={async () => {
          setAssessmentProgramActivity(null);
          await refetchTodoProgramActivities();
        }}
      />
    );
  }

  if (esignProgramActivity && authedPatient) {
    return (
      <>
        <div className="flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-2 px-20">
          <IconButton
            IconComponent={ArrowNarrowLeftIcon}
            iconClassName="text-green-100 w-6"
            aria-label="Back"
            onClick={() => {
              setEsignProgramActivity(null);
            }}
          >
            <div className="small-caption ml-3 font-bold text-green-100">
              Back
            </div>
          </IconButton>
        </div>
        <Esign
          patientHomeMode
          programActivity={esignProgramActivity}
          providerName={esignProgramActivity.sentByName}
          providerEmail={esignProgramActivity.sentByEmail}
          patient={authedPatient}
          onComplete={async () => {
            setTimeout(async () => {
              setEsignProgramActivity(null);
              await refetchTodoProgramActivities();
            }, 4000);
          }}
        />
      </>
    );
  }

  return (
    <div className="md:h-[calc(100vh-var(--top-nav-height))] md:overflow-y-hidden">
      <PatientHomeIllustration className="l-0 z-1 fixed h-screen" />
      <DownloadAppBanner className="relative top-0 z-10 w-full sm:hidden" />
      <PageContainer
        noPadding
        containerClassName="md:px-16 px-6 py-8 relative z-2"
      >
        <Transition
          show={Boolean(todoProgramActivities && !todoProgramActivitiesError)}
          {...defaultTransitionProps}
        >
          <div className="md:hidden">
            <WelcomeText firstName={authedPatient.firstName} />
          </div>
          <div className="flex flex-col md:flex-row md:space-x-6">
            {/* Left column: Welcome text (desktop) and My plan */}
            <div className="order-2 w-full md:order-1 md:w-1/3 md:min-w-[350px]">
              <div className="hidden md:block">
                <WelcomeText firstName={authedPatient.firstName} />
              </div>
              <div className="mb-6 flex w-full flex-col rounded-lg border border-neutral-75 bg-white pt-6 md:mb-0 md:max-h-[550px]">
                <div className="border-b border-neutral-75 pl-6 pb-6 text-small font-medium">
                  My plan
                </div>
                <div className="h-full w-full overflow-y-auto">
                  {!Boolean(todoProgramActivities?.length) ? (
                    <EmptySection
                      IconComponent={IllustrationClipboard}
                      iconClassName="text-green-125"
                      titleText="No pending actions"
                      subTitleText={`${providerFirstName} will share actions and resources with you`}
                    />
                  ) : (
                    <div className="h-full w-full overflow-y-auto">
                      {todoProgramActivities.map(
                        (todoProgramActivity, index) => (
                          <ProgramActivityCard
                            key={`programActivityCard_${index}`}
                            isSmallStyle
                            userType={UserType.Patient}
                            programActivity={todoProgramActivity}
                            isFirstCard={index === 0}
                            isLastCard={
                              index === todoProgramActivities.length - 1
                            }
                            onClickProgramActivity={
                              handleOnClickProgramActivity
                            }
                          />
                        ),
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Right column: Sessions (if available) and Mail */}
            <div
              className={classNames(
                'order-1 w-full md:order-2 md:max-h-[calc(100vh-var(--top-nav-height))] md:w-2/3 md:min-w-[398px] md:overflow-y-scroll md:pb-12',
                !hasCalendlyIntegration && 'md:pt-24',
              )}
            >
              {hasCalendlyIntegration && (
                <div className="mb-6 rounded-lg border border-neutral-75 bg-white px-6 pt-[30px] md:mb-2 md:border-none md:bg-transparent">
                  <div className="mb-6 flex h-[48px] flex-row items-center justify-between">
                    <div className="flex flex-row items-center justify-start">
                      <div className="mr-4 text-small font-medium">
                        Sessions
                      </div>
                    </div>
                    <Transition
                      show={Boolean(
                        calendlyEventTypesData && !calendlyEventTypesDataError,
                      )}
                      {...defaultTransitionProps}
                    >
                      <Button
                        title={
                          !isMobileDevice ? 'Book a session' : 'Book session'
                        }
                        IconComponent={CalendarIcon}
                        theme="secondary-white"
                        iconPosition="left"
                        size={isMobileDevice ? 'small' : 'medium'}
                        className="!text-green-100 shadow-100"
                        onClick={() => setPatientCalendlyBookingModalOpen(true)}
                      />
                    </Transition>
                  </div>
                  <div className="flex min-h-[186px] w-full">
                    {scheduledEventsDataLoading ? (
                      <div className="flex h-full w-full flex-row items-center justify-center">
                        <Spinner className="h-6 w-6" />
                      </div>
                    ) : (
                      <div className="w-full">
                        <Transition
                          show={Boolean(
                            scheduledEvents?.length === 0 &&
                              !scheduledEventsDataError,
                          )}
                          className="w-full"
                          {...defaultTransitionProps}
                        >
                          <EmptySection
                            IconComponent={IllustrationCalendar}
                            iconClassName="text-green-100"
                            titleText="No sessions booked yet"
                            subTitleText={`Check ${providerFirstName}'s availability and book a session`}
                          />
                        </Transition>
                        <Transition
                          show={Boolean(
                            scheduledEvents && !scheduledEventsDataError,
                          )}
                          {...defaultTransitionProps}
                        >
                          <div className="flex w-full flex-col items-start">
                            <div className="mb-6 flex w-full flex-wrap gap-4 md:gap-7">
                              {scheduledEvents?.map((scheduledEvent, index) => (
                                <PatientScheduledEvent
                                  key={index}
                                  showRelativeTime={index === 0}
                                  scheduledEvent={scheduledEvent}
                                  onClickCancelEvent={() => {
                                    setSelectedScheduledEvent(scheduledEvent);
                                    setConfirmCancelModalOpen(true);
                                  }}
                                />
                              ))}
                            </div>
                            {hasScheduledEvents &&
                              scheduledEventsData?.scheduledEvents.length >
                                CARD_SHORT_LIST_LENGTH && (
                                <SeeAllButton
                                  title="See all sessions"
                                  onClick={() => navigate('/client/sessions')}
                                />
                              )}
                          </div>
                        </Transition>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="mb-6 rounded-lg border border-neutral-75 bg-white p-6 pt-8 md:mb-0 md:border-none md:bg-transparent">
                <div className="mb-6 flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start">
                    <span className="text-small font-medium">Mail</span>
                  </div>
                </div>
                <Transition
                  show={Boolean(
                    programFollowUps?.length === 0 && !programFollowUpsError,
                  )}
                  className="w-full"
                  {...defaultTransitionProps}
                >
                  <EmptySection
                    IconComponent={IllustrationMail}
                    iconClassName="text-blue-100"
                    titleText="No mail yet"
                    subTitleText={`Keep an eye out for mail from ${providerFirstName}`}
                  />
                </Transition>
                <Transition
                  show={Boolean(programFollowUps && !programFollowUpsError)}
                  {...defaultTransitionProps}
                >
                  <div className="flex w-full flex-col items-start">
                    <div className="mb-6 flex w-full flex-wrap gap-4 md:gap-7">
                      {programFollowUps &&
                        programFollowUps?.map((programFollowUp, index) => (
                          <PatientMailCard
                            key={`PatientMailCard_${index}`}
                            programFollowUp={programFollowUp}
                            isLatestFollowUp={index === 0}
                          />
                        ))}
                    </div>
                    {programFollowUpsData?.programFollowUps?.length >
                      CARD_SHORT_LIST_LENGTH && (
                      <SeeAllButton
                        title="See all mail"
                        onClick={() => navigate('/client/follow-ups')}
                      />
                    )}
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </Transition>
      </PageContainer>
      <Button
        IconComponent={ChatIcon}
        theme="secondary-white"
        iconPosition="center"
        className="fixed right-5 bottom-5 rounded-full px-[12px] !text-blue-100 shadow-100"
        onClick={() => navigate('/client/messages')}
      />
      <PatientCalendlyBookingModal
        isModalOpen={patientCalendlyBookingModalOpen}
        setClosed={() => setPatientCalendlyBookingModalOpen(false)}
        calendlyEventTypes={calendlyEventTypes}
        authedPatient={authedPatient}
        onCalendlyBooked={async () => {
          await refetchPatientScheduledEvents();
        }}
      />
      <ConfirmCancelModal
        isModalOpen={confirmCancelModalOpen}
        setClosed={() => setConfirmCancelModalOpen(false)}
        selectedScheduledEvent={selectedScheduledEvent}
        setSelectedScheduledEvent={setSelectedScheduledEvent}
        refetchScheduledEventsData={refetchPatientScheduledEvents}
        patientCancelMode
      />
      <ProgramActivitySlideover
        isOpen={isProgramActivitySlideoverOpen}
        onClose={() => setIsProgramActivitySlideoverOpen(false)}
        programActivity={selectedProgramActivity}
        userType={UserType.Patient}
        onMarkComplete={async () => {
          if (selectedProgramActivity) {
            await completeProgramActivity({
              variables: {
                input: {
                  programActivityId: selectedProgramActivity.id,
                },
              },
            });

            refetchTodoProgramActivities();
            setIsProgramActivitySlideoverOpen(false);
          }
        }}
        onDismiss={async () => {
          if (selectedProgramActivity) {
            setIsProgramActivitySlideoverOpen(false);
          }
        }}
        onSubmitPdf={() => {
          refetchTodoProgramActivities();
          setIsProgramActivitySlideoverOpen(false);
        }}
        patientName={patientName}
      />
    </div>
  );
};

export default PatientHome;
