import React, { useEffect, useState } from 'react';
import { usePublicStorefrontQuery } from '../../../generated/graphql';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HOMEPAGE_URL } from '../../lib/constants';
import PageContainer from '../../components/Containers/PageContainer';
import PractitionerBanner from '../../components/Storefront/PractitionerBanner';
import PractitionerInfoContainer from '../../components/Storefront/PractitionerInfoContainer';
import PractitionerInfoLeftColumn from '../../components/Storefront/PractitionerInfoLeftColumn';
import PractitionerInfoRightColumn from '../../components/Storefront/PractitionerInfoRightColumn';
import Loading from '../Loading';
import StorefrontIntakeCompleteModal from '../../components/Modals/StorefrontIntakeCompleteModal';
import StorefrontFooter from './StorefrontFooter';
import useDisableOverscroll from '../../hooks/useDisableOverscroll';

const PublicStorefront: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  useDisableOverscroll();

  const slug = params.slug;
  const fromIntakeSuccess = (location.state as { fromIntakeSuccess?: boolean })
    ?.fromIntakeSuccess;

  const { data: storefrontData, error: storefrontError } =
    usePublicStorefrontQuery({
      variables: {
        slug,
      },
      skip: !slug,
    });

  const [
    isStorefrontIntakeCompleteModalOpen,
    setIsStorefrontIntakeCompleteModalOpen,
  ] = useState(false);

  if (!slug || storefrontError) {
    navigate(HOMEPAGE_URL, { replace: true });
  }

  const storefront = storefrontData?.publicStorefront;

  useEffect(() => {
    if (storefront && fromIntakeSuccess) {
      setIsStorefrontIntakeCompleteModalOpen(true);
    }
  }, [storefront, fromIntakeSuccess]);

  const [isAvatarImagePopUpOpen, setIsAvatarImagePopUpOpen] = useState(false);

  return (
    <div>
      {storefront ? (
        <>
          <PageContainer containerClassName="pt-0 pb-0 px-0 md:px-32 min-h-screen min-w-full items-center">
            <div
              onClick={() =>
                isAvatarImagePopUpOpen && setIsAvatarImagePopUpOpen(false)
              }
              className="flex min-h-screen flex-col justify-between md:max-w-[1024px]"
            >
              <div className="flex flex-col justify-start">
                <PractitionerBanner
                  avatarImageUrl={
                    storefront.profileImageUrl ??
                    storefront.providerProfileImageUrl
                  }
                  setIsAvatarImagePopUpOpen={setIsAvatarImagePopUpOpen}
                  isAvatarImagePopUpOpen={isAvatarImagePopUpOpen}
                  avatarName={storefront.providerName}
                  bannerImageUrl={storefront.bannerImageUrl}
                  initialBannerPosition={storefront.bannerPosition}
                />
                <PractitionerInfoContainer>
                  <PractitionerInfoLeftColumn
                    providerName={storefront.providerName}
                    roleTitle={storefront.providerRoleTitle}
                    ctaFormLabel={storefront.providerCtaFormLabel}
                    ctaButtonText={storefront.providerCtaButtonText}
                    providerStorefrontSlug={slug}
                  />
                  <PractitionerInfoRightColumn
                    tagline={storefront.tagline}
                    about={storefront.about}
                    services={storefront.services}
                  />
                </PractitionerInfoContainer>
              </div>
              <StorefrontFooter />
            </div>
          </PageContainer>
          <StorefrontIntakeCompleteModal
            isModalOpen={isStorefrontIntakeCompleteModalOpen}
            setClosed={() => setIsStorefrontIntakeCompleteModalOpen(false)}
            avatarImageUrl={
              storefront.profileImageUrl ?? storefront.providerProfileImageUrl
            }
            avatarName={storefront.providerName}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PublicStorefront;
